import styled, { createGlobalStyle } from 'styled-components'
import { typography, color } from '@renderbus/common/theme'
import UploadIcon from '../images/render-contest/upload_gray@2x.png'
import CheckedIcon from '../images/render-contest/check@2x.png'
import WarningIcon from '../images/render-contest/warning@2x.png'
import buttonHover from '../images/rc2025/content-button-hover.webp'
import button from '../images/rc2025/content-button.webp'
import YouSheBiaoTiHei from '../font/YouSheBiaoTiHei.ttf'

export const RCSubmitStyle = createGlobalStyle`
  @font-face {
    font-family: 'YouSheBiaoTiHei';
    src: url(${YouSheBiaoTiHei});
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
  html {
    background: #EEEEEE;
    color: #333333;
  }
  button {
    border: unset;
    cursor: pointer;
  }
`

export const BannerContainer = styled.div`
  img {
    width: 100%;
  }
`

export const RCFormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -5.95vw;
`

export const RCForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 1201px;
  min-height: 961px;
  border: 1px solid #f6f8fa;
  background-color: ${color.white};
  margin-bottom: 80px;
  padding: 50px 0 80px;
  box-shadow: 0px 4px 24px 0px rgba(24, 64, 112, 0.08);
`
export const FormTitle = styled.p`
  align-self: center;
  font-size: ${typography.h3};
  color: #3d3d3d;
  font-weight: bold;
`
export const FormSubTitle = styled.div`
  align-self: center;
  font-size: ${typography.textSmall};
  color: #999999;
  line-height: 20px;
  margin-top: 8px;
`
export const FormItemLabel = styled.span`
  margin-left: ${(p) => `${p.left}px`};
  margin-right: ${(p) => `${p.right}px`};
  margin-top: ${(p) => `${p.top}px`};
  color: ${color.panel};
  font-size: ${typography.text};
  span {
    font-size: ${typography.textSmall};
    color: #666666;
    font-weight: 350;
    margin-left: 8px;
    margin-bottom: 8px;
  }
  ::before {
    display: ${(p) => (p.notNecessary ? 'none' : 'inline-block')};
    content: '';
    width: 6px;
    height: 6px;
    background: #f14638;
    border-radius: 50%;
    margin-right: 8px;
  }
`
export const FormItemContent = styled.div``
export const FormInput = styled.input`
  width: ${(p) => (p.width ? `${p.width}px` : '320px')};
  height: ${(p) => (p.height ? `${p.height}px` : '40px')};
  margin-right: ${(p) => (p.right ? `${p.right}px` : '')};
  padding: 10px 20px;
  background-color: ${(p) => (p.value ? '#ffffff' : '#F4F4F4')};
  border: 1px solid #dddddd;
  border-radius: 2px;
  outline-style: none;
  position: relative;
  font-size: 14px;
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-input-placeholder {
    font-size: ${typography.textSmall};
    font-weight: 350;
  }
  &.workname {
    margin-top: 8px;
  }
  &:active,
  &:focus {
    background-color: #ffffff;
  }
`
export const ErrorText = styled.div`
  display: none;
  color: red;
  position: absolute;
  /* height: 17px; */
  font-size: 12px;
  margin-top: 6px;
  align-items: center;
  &#workName-error,
  &#workName-format-error,
  &#workDescription-error,
  &#workDescription-length-error {
    bottom: -22px;
  }
  &::before {
    display: inline-block;
    width: 16px;
    height: 16px;
    content: '';
    background: url(${WarningIcon}) no-repeat;
    background-size: 100% 100%;
    margin-right: 4px;
  }
`
export const BottomLine = styled.div`
  align-self: center;
  width: 938px;
  height: 0px;
  opacity: 1;
  border: 1px solid #eeeeee;
  margin-top: 50px;
`
export const RCSubmitButton = styled.button`
  width: 188px;
  height: 49px;
  background: url(${button});
  transition: all 0.3s ease-in-out;
  background-size: 100%;
  font-family: YouSheBiaoTiHei;
  font-size: 20px;
  margin-top: 40px;
  margin-right: 50%;
  transform: translate(50%);
  span {
    background: linear-gradient(274deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 104%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover {
    background: url(${buttonHover});
    background-size: 100%;
  }
`

export const QRContainer = styled.div`
  margin-top: 41px;
  display: flex;
  align-items: center;
  margin-right: 50%;
  transform: translate(50%);
  img {
    width: 120px;
    height: 120px;
  }
  div {
    margin-left: 55px;
    p {
      margin: unset;
      font-size: 14px;
      color: #666666;
      line-height: 24px;
      width: max-content;
    }
  }
`
export const RCRadioLabel = styled.span`
  font-size: 14px;
  margin-left: 13px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    cursor: pointer;
    left: -30px;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translate(0, -50%);
    border: 2px solid #999999;
    border-radius: 50%;
    pointer-events: none;
  }
`
export const RCRadioInput = styled.input`
  color: yellow;
  background: yellow;
  margin-left: 4px;
  opacity: 0;
  &:checked {
    + ${RCRadioLabel} {
      &::before {
        border-color: #3fb6ff;
      }
      &::after {
        content: '';
        position: absolute;
        cursor: pointer;
        width: 9px;
        height: 9px;
        background: #3fb6ff;
        border: 1px solid #3fb6ff;
        border-radius: 50%;
        left: -24.5px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
`
export const RCRadioContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 40px;
  font-weight: 400;
  color: #666666;
  font-size: ${typography.textSmall};
  margin: 20px 270px 0 30px;
`
export const RCCheckbox = styled.input`
  cursor: pointer;
  opacity: 0;
  margin-right: 8px;
  + span {
    position: relative;
    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
      border: 1px solid #666666;
      background: transparent;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
    }
  }
  &:checked {
    + span::before {
      border: 1px solid #539df6;
    }
    + span::after {
      background: url(${CheckedIcon}) no-repeat;
      background-size: 100% 100%;
    }
  }
`
export const WorkUploadInput = styled.div`
  margin-right: 57px;
  width: 263px;
  height: 150px;
  border: ${(p) =>
    p.uploading || (p.uploadResult && p.uploadResult.success)
      ? '1px solid #F0F0F0'
      : p.uploadResult && p.uploadResult.fail
      ? '1px solid #F14638'
      : '2px dashed #DDDDDD'};
  background: ${(p) =>
    p.uploading ? '#F6FCFF' : p.uploadResult && p.uploadResult.fail ? '#FFF7F7' : `unset`};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &#upload-container {
    background: ${(p) =>
      p.uploading || (p.uploadResult && p.uploadResult.success)
        ? '#F6FCFF'
        : p.uploadResult && p.uploadResult.fail
        ? '#FFF7F7'
        : `unset`};
  }
  .upload-icon {
    background: url(${UploadIcon}) no-repeat;
    background-size: 100%;
    width: 30px;
    height: 30px;
  }
  > p {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 14px;
    color: #333333;
    line-height: 24px;
    cursor: pointer;
  }
  > span {
    font-size: 12px;
    font-weight: 400;
    color: #b6b6b6;
    line-height: 24px;
  }
  > p.file-name {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin: 8px 13px 4px;
  }
  > p.file-size {
    color: #57cfff;
    font-size: 12px;
    margin: unset;
  }
  > p.fail-tip {
    color: #f14638;
    font-size: 12px;
    margin: unset;
  }
  > .cover-progress,
  > .progress {
    width: 0;
    height: 2px;
    border-radius: 2px;
    background: #57cfff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  img.png-icon {
    width: 29px;
    height: 29px;
  }
  img.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
  .success-img {
    object-fit: contain;
    width: 263px;
    height: 150px;
    border-radius: 4px;
  }
`
export const FormItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(p) => (p.top ? `${p.top}px` : '37px')};
  position: relative;
  &.name-phone {
    margin-top: 40px;
  }
  &.style-3d {
    margin-right: 18px;
  }
  &.echo {
    margin-right: 18px;
  }
  &.work-name,
  &.sources {
    align-items: unset;
    flex-direction: column;
  }
  &.work-description {
    align-items: unset;
    flex-direction: column;
    position: relative;
    textarea {
      margin-right: 132px;
      margin-top: 8px;
      height: 198px;
      width: 618px;
      background-color: ${(p) => (p.value ? '#ffffff' : '#F4F4F4')};
      overflow-y: auto;
      border: 1px solid #dddddd;
      border-radius: 4px;
      outline-style: none;
      resize: none;
      &:active,
      &:focus {
        background-color: #ffffff;
      }
      &::-webkit-input-placeholder {
        font-size: ${typography.textSmall};
        font-weight: 350;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
`

export const RCDialogContainer = styled.div`
  position: fixed;
  display: ${(p) => (p.isActive ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
  .content {
    display: flex;
    padding: 60px 60px 60px 40px;
    border-radius: 14px 14px 14px 14px;
    width: 605px;
    height: 295px;
    background: #ffffff;
    img {
      width: 180px;
      height: 180px;
      background: linear-gradient(314deg, #f0feea -1%, #febd2f 20%, #26fdf8 69%, #ffffff 101%),
        linear-gradient(229deg, rgba(218, 244, 255, 0.5) 5%, rgba(126, 214, 250, 0.5) 100%);
      padding: 19px;
      border-radius: 10px;
    }
    .text {
      margin-left: 26px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        margin: 0 0 21px 0;
        color: ${({ isSuccess }) => (isSuccess ? '#333333' : '#F14638')};
        font-size: 28px;
        line-height: 30px;
        font-weight: bold;
      }
      span {
        color: #666666;
        font-size: 14px;
        line-height: 14px;
      }
      button {
        width: 188px;
        height: 49px;
        background: url(${button});
        transition: all 0.3s ease-in-out;
        background-size: 100%;
        font-family: YouSheBiaoTiHei;
        border: none;
        cursor: pointer;
        span {
          font-size: 20px;
          background: linear-gradient(274deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 104%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        &:hover {
          background: url(${buttonHover});
          background-size: 100%;
        }
      }
    }
  }
`
export const FormCategoryItem = styled.div`
  width: 1060px;
  display: flex;
  margin-top: 40px;
`
